import React, { useState } from "react";
import { ButtonHTMLAttributes } from "react";
import { cn } from "@/lib/utils";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
};
import Constants from "@/utils/Constants";
export default function ButtonWithLoading({
  children,
  isLoading,
  ...rest
}: Props) {
  return (
    <button
      {...rest}
      className={cn(rest.className, `${isLoading ? "btn-disabled" : ""}`)}
    >
      {isLoading && (
        <span className={`${Constants.buttonLoadingStyle} `}></span>
      )}
      {children}
    </button>
  );
}
