"use client";
import HeroWavesSVG from "@/components/ui/SVGIcons/HeroWaves";
import CoreConstants from "@mltask/core/CoreConstants";
import { shuffle } from "@mltask/core/Utils/ArrayHelper";
import { useQuery } from "@tanstack/react-query";
// import dynamic from "next/dynamic";
// import { useRef } from "react";
// import CreatePresetHero from "./CreatePresetHero";
import { fetchPublicTasks } from "./PublicTasksLoader";
import WaitlistHero from "./WaitlistHero";
import CheckoutAppsHero from "./CheckoutAppsHero";
// const HeroParallax = dynamic(() =>
//   import("@/components/ui/hero-parallax").then((mod) => mod.HeroParallax)
// );

// const MovingGetStartedButton = dynamic(
//   () => import("./MovingGetStartedButton"),
//   {
//     ssr: false,
//   }
// );

type Props = {};

export default function MainHero({}: Props) {
  const {
    data: publicTasks,
    isLoading,
    isSuccess,
    isStale,
    isError: isErrorQuery,
  } = useQuery({
    queryKey: ["fetchPublicTasks"],
    queryFn: async () => {
      const output = await fetchPublicTasks();
      const results = output?.results;
      if (results == null) return;
      results.tasks = shuffle(results.tasks);
      return results;
    },
    staleTime: CoreConstants.fechPublicTasksCacheDurationTimeInMilS,
  });

  // const ref = useRef<HTMLDivElement>(null);

  // function renderHero() {
  //   return (
  //     <section ref={ref} className="flex w-full flex-col items-center  ">
  //       <div className="flex w-full justify-center overflow-visible bg-base-200">
  //         <HeroParallax tasks={publicTasks?.tasks ?? []} />
  //       </div>
  //       <MovingGetStartedButton
  //         getRef={() => {
  //           return ref;
  //         }}
  //       />
  //       <HeroWavesSVG fillColorCSS="fill-base-200" inverted={false} />
  //     </section>
  //   );
  // }
  function renderWaitlistHero() {
    return (
      <section className="flex w-full flex-col items-center">
        <div className="flex w-full justify-center overflow-visible bg-base-200 py-6">
          <WaitlistHero tasks={publicTasks?.tasks ?? []} />
        </div>
        <HeroWavesSVG fillColorCSS="fill-base-200" inverted={false} />
      </section>
    );
  }
  return (
    <div className="flex grow flex-col">
      {/* {renderHero()} */}
      {renderWaitlistHero()}
      <CheckoutAppsHero />
      {/* <CreatePresetHero /> */}
    </div>
  );
}
